import {
    motion,
    useTransform,
    useViewportScroll,
    AnimatePresence,
} from 'framer-motion';
import { useState, useEffect } from 'react';
import * as functions from '../../utils/functions.js';
import VideoGallery from '../VideoGallery';
import YouTube from 'react-youtube';
import black from '../../images/outros/black.webp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import infos from '../../utils/infosGallery.json';

interface GalleryContainerProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    altura: number;
}

function GalleryContainer(props: GalleryContainerProps) {
    const [zGallery, setZGallery] = useState(-1);

    const a = props.altura;
    const { scrollYProgress } = useViewportScroll();

    const [showVideo, setShowVideo] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [videoH, setVideoH] = useState(window.innerHeight / 2);

    const handleResize = () => {
        setVideoH(window.innerHeight / 2);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        function scroll() {
            if (
                functions.adaptaAlturaInv(a, [scrollYProgress.get()])[0] < 0.7
            ) {
                setZGallery(-1);
            } else {
                setZGallery(10);
            }
        }
        window.addEventListener('scroll', scroll);
        return () => window.removeEventListener('scroll', scroll);
    }, [scrollYProgress]);

    const videoScale = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0.56, 0.76]),
        [3.45, 0.5]
    );

    const topPos = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0.56, 0.76]),
        ['7.6vh', '12.5vh']
    );

    const [images, setImages] = useState([]);

    function handleImageClick(id: number, name: string) {
        if (functions.adaptaAlturaInv(a, [scrollYProgress.get()])[0] >0.76 && functions.adaptaAlturaInv(a, [scrollYProgress.get()])[0] <0.85) {
            setShowVideo(true);
            setVideoId(infos[name as keyof typeof infos][id].url);
            document.body.style.overflow = 'hidden';
        }
    }
    const handleCloseButtonClick = () => {
        setShowVideo(false);
        setVideoId('');
        setTimeout(() => {
            document.body.style.overflow = 'auto';
        }, 500);
    };

    return (
        <motion.div
            className='GalleryContainer.motion'
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: '#131313',
                height: '75vh',
                width: '120vh',
                flexShrink: 0,
                left: '-100.3vh',
                top: topPos,
                zIndex: zGallery,
                overflow: 'hidden',
            }}
            transition={{ type: 'tween', ease: 'easeInOut' }}>
            <motion.div style={{ scale: videoScale }}>
                <VideoGallery
                    name='Clipes'
                    images={infos.Clipes}
                    onDataChange={handleImageClick}
                />
                <VideoGallery
                    name='Institucional - Publicidade'
                    images={infos['Institucional - Publicidade']}
                    onDataChange={handleImageClick}
                />
                <VideoGallery
                    name='Eventos'
                    images={infos.Eventos}
                    onDataChange={handleImageClick}
                />
            </motion.div>
            <AnimatePresence>
                {showVideo && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='video-box'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            scale: videoScale,
                            position: 'relative',
                            margin: 'auto',
                            top: '-87%',
                            height: '50vh',
                        }}>
                        <HighlightOffIcon
                            style={{
                                position: 'relative',
                                left: 30 + (videoH * 16) / 9 / 2,
                                top: -20,
                                zIndex: 93,
                                fontSize: '5vh',
                                color: 'white',
                            }}
                            onClick={handleCloseButtonClick}
                        />
                        <YouTube
                            videoId={videoId}
                            style={{ zIndex: 93 }}
                            opts={{
                                height: videoH,
                                width: (videoH * 16) / 9,
                                playerVars: {
                                    autoplay: 1,
                                    color: 'white',
                                    //controls: 0,
                                    rel: 0,
                                    showinfo: 0,
                                    //modestbranding: 1,
                                },
                            }}
                        />

                        <img
                            src={black}
                            className='overlay'
                            style={{
                                position: 'relative',
                                top: '-100vh',
                                left: 0,
                                width: '300%',
                                height: '300%',
                                zIndex: 92,
                                opacity: 0.8,
                            }}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}

export default GalleryContainer;
/**/
