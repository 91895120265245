import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import gradiente from '../../images/outros/gradiente_botao.png';

const imgW = 33;
const imgM = 1;
const imgTotal = imgM * 2 + imgW;
interface VideoGalleryProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    images: {
        id: number;
        src: string;
        title: string;
    }[];
    onDataChange: (data: number, name: string) => void;
}

export default function VideoGallery(props: VideoGalleryProps) {
    const images = props.images.concat(props.images.concat(props.images));

    const [indexScroll, setIndexScroll] = useState(0);
    const scrollInicial = images.length % 2 == 0 ? -imgTotal / 2 : 0;
    const [scrollX, setScrollX] = useState(scrollInicial);
    const [translateX, setTranslateX] = useState(0);
    const handlePrev = () => {
        setIndexScroll(indexScroll - 1);
        let x = scrollX + imgTotal;
        setScrollX(x);
    };
    const handleNext = () => {
        setIndexScroll(indexScroll + 1);
        let x = scrollX - imgTotal;
        setScrollX(x);
    };
    useEffect(() => {
        if (
            indexScroll == props.images.length ||
            indexScroll == -props.images.length
        ) {
            setIndexScroll(0);
            setTranslateX(-(scrollX - scrollInicial));
        }
        //console.log('index: ' + indexScroll + ' x: ' + scrollX + 'vh');
    }, [indexScroll]);

    return (
        <div
            id='VideoGallery'
            style={{
                marginTop: '3vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                alignItems: 'center',
            }}>
            <div
                style={{
                    position: 'relative',
                    width: '80vw',
                    height: '0vh',
                    backgroundColor: 'red',
                }}>
                <img
                    src={gradiente}
                    style={{
                        position: 'absolute',
                        left: '-3vh',
                        top: '1.5vh',
                        width: imgW + 2 + 'vh',
                        height: (imgW * 9) / 16 + 0.5 + 'vh',
                        zIndex: 98,
                    }}
                />
                <h1
                    style={{
                        position: 'absolute',
                        left: '10vh',
                        zIndex: 100,
                        top: '-2.7vh',
                        fontSize: '3vh',
                        color: 'white',
                    }}>
                    {(props.name)}
                </h1>
                <div
                    style={{
                        position: 'absolute',
                        left: '1vh',
                        zIndex: 99,
                        top: '6.5vh',
                    }}
                    onClick={handlePrev}>
                    <NavigateBeforeIcon
                        className='movieRow--arrow'
                        style={{ fontSize: '10vh', color: 'white' }}
                    />
                </div>
                <img
                    src={gradiente}
                    style={{
                        position: 'absolute',
                        right: '-3vh',
                        top: '1.5vh',
                        width: imgW + 2 + 'vh',
                        height: (imgW * 9) / 16 + 0.5 + 'vh',
                        transform: 'scaleX(-1)',
                        zIndex: 98,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        right: '1vh',
                        zIndex: 99,
                        top: '6.5vh',
                    }}
                    onClick={handleNext}>
                    <NavigateNextIcon
                        className='movieRow--arrow'
                        style={{ fontSize: '10vh', color: 'white' }}
                    />
                </div>
            </div>
            <motion.div
                style={{
                    translateX: translateX + 'vh',
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '22vh',
                }}
                animate={{ x: scrollX + 'vh' }}
                transition={{ type: 'spring' }}>
                {images.map((image, index) => (
                    <img
                        id={'image ' + image.id}
                        key={index}
                        style={{
                            position: 'relative',
                            width: imgW + 'vh',
                            height: (imgW * 9) / 16 + 'vh',
                            margin: imgM + 'vh',
                        }}
                        src={image.src}
                        onClick={() => props.onDataChange(image.id, props.name)}
                        alt={image.title}
                    />
                ))}
            </motion.div>
        </div>
    );
}
/*
 */
