import {
    motion,
    useTransform,
    useViewportScroll,
    useMotionValue,
} from 'framer-motion';
import React from 'react';
import * as functions from '../../utils/functions.js';
import Timecode from '../Timecode';
import GalleryContainer from '../GalleryContainer';

import BMFrame from '../../images/bmpcc4k.webp';

import { Sticky } from '../../styles';

interface myProps extends React.InputHTMLAttributes<HTMLInputElement> {
    altura: number;
}

function Second(props: myProps) {
    const a = props.altura;

    const originX = useMotionValue(0.515);
    const originY = useMotionValue(0.5);

    const { scrollYProgress } = useViewportScroll();

    const bmScale = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0.56, 0.76]),
        [0.8, 2.5]
    );
    const bgScale = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0.56, 0.76]),
        ['150%', '100%']
    );

    return (
        <Sticky className='second'>
            <motion.div
                className='second.motion'
                style={{
                    originX,
                    originY,
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    margin: '0 auto',
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    scale: bmScale,
                }}
                transition={{ type: 'tween', ease: 'easeInOut' }}>
                <Timecode className='timecode' altura={a} />
                <img
                    className='bmFrame'
                    style={{
                        backgroundColor: '',
                        position: 'relative',
                        height: '100%',
                        left: '66.5vh',
                        flexShrink: 0,
                    }}
                    src={BMFrame}
                    alt=''
                />

                <GalleryContainer className='GalleryContainer' altura={a} />
            </motion.div>
        </Sticky>
    );
}

export default Second;
