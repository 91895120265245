import styled from 'styled-components';
import { motion } from 'framer-motion';
import wallpaper from '../images/wallpaper.webp';



export const Main = styled.main`
    .navbar {
        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 30%;
            margin-left: 5%;
            margin-top: 1%;
            list-style-type: none;
        }
        li{
            font-weight: 600;
        }
    }

    .first {
        background: #eeeeee;
        overflow: hidden;
    }

    .second {
        //background: #19da5d;
        overflow: hidden;

    }
`;

export const Section = styled.div`
    position: relative;
`;

export const Sticky = styled(motion.div)`
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
`;
