import React from 'react';
import { useViewportScroll } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';
import * as functions from '../../utils/functions.js';

interface myProps extends React.InputHTMLAttributes<HTMLInputElement> {
    altura: number;
}

const min = Math.floor(Math.random() * (22 - 0 + 1));
const hora = Math.floor(Math.random() * (2 - 0 + 1));

export default function Timecode(props: myProps) {
    const a = props.altura;

    const { scrollY } = useViewportScroll();
    const [scrolled, setScrolled] = useState(0);

    var frames = 0;
    const [framesExibe, setFramesExibe] = useState(0);
    const [seg, setSeg] = useState(0);


    const lastScrollY = useRef(0);

    useEffect(() => {
        function updateTime() {
            const currentScrollY = window.pageYOffset;

            if (currentScrollY > lastScrollY.current) {
                frames++;
                setFramesExibe(frames);
            } else {
                frames--;
                if (frames < 0) {
                    frames = 0;
                }
                setFramesExibe(frames);
            }
            setSeg(Math.floor((frames / 24) % 60));
            lastScrollY.current = currentScrollY;
        }

        window.addEventListener('scroll', updateTime);
        return () => window.removeEventListener('scroll', updateTime);
    }, [scrollY]);

    return (
        <>
            <h1
                style={{
                    position: 'relative',
                    fontSize: '4vh',
                    zIndex: '1',
                    left: '152.5vh',
                    top: '20vh ',
                    color: 'red',
                    WebkitTextStroke: '2px black',
                }}
            >
                {hora.toString().padStart(2, '0')}:{min.toString().padStart(2, '0')}:{seg.toString().padStart(2, '0')}:{(framesExibe % 24).toString().padStart(2, '0')}
            </h1>
        </>
    );
}
