import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        font-smooth: always;
        font-smooth: 2em;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    ::-webkit-scrollbar {
        display: none;
    }

    
`;
