import {
    motion,
    useTransform,
    useViewportScroll,
    useSpring,
} from 'framer-motion';
import React from 'react';
import { useEffect, useState } from 'react';
import { Sticky } from '../../styles';
import * as functions from '../../utils/functions.js';

import macFrame from '../../images/mac_1.webp';
import logo from '../../images/Logo_Branca.webp';

import AnimatedImage from '../AnimatedImage';

interface myProps extends React.InputHTMLAttributes<HTMLInputElement> {
    altura: number;
}

function First(props: myProps) {
    //Pega dimensões da tela e atualiza dinamicamente
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    var ratio = screenWidth/screenHeight;
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    //Fim dimensões

    // Pega a altura total da página
    const a = props.altura;

    //Pega o scroll atual da página
    const { scrollYProgress } = useViewportScroll();

    //Altera o valor de scale do notebook de acordo com o scroll
    const frameScale = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0, 0.2]),
        [2.3, 0.5]
    );

    //Altera o valor de posição horizontal do notebook de acordo com o scroll
    const frameX = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0.25, 0.3]),
        ['0%', '28%']
    );

    //Altera os valores de posição horizontal e opacidade do texto de acordo com o scroll
    const textX = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0.25, 0.3]),
        ['10%', '-65%']
    );
    const textOpacity = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0.25, 0.3]),
        ['0%', '100%']
    );

    //Altera o valor posição vertical do notebook fazendo o oposto do scroll, gerando parallax
    const frameY = useTransform(
        scrollYProgress,
        functions.adaptaAltura(a, [0, 0.2, 0.422, 0.6]),
        ['40%', '0%', '0%', '100%']
    );

    return (
        <Sticky className='first'>
            <motion.div
                className='container-pc'
                style={{
                    position: 'absolute',
                    top: frameY,
                    left: frameX,
                    right: 0,
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    scale: frameScale,
                    flex: 1,
                    flexDirection: 'row',
                }}>
                <motion.div
                    className='text-notebook'
                    style={{
                        position: 'absolute',
                        left: textX,
                        opacity: textOpacity,
                        scale: 4,
                        textAlign: 'right',
                        width: '20%',
                        whiteSpace: 'nowrap',
                        zIndex: 1,
                    }}>
                    <h1 style={{ fontSize: '1.8vw' }}>
                        Eleve o nível do
                        <br />
                        seu conteúdo
                    </h1>
                    <p style={{ lineHeight: '1.4' }}>
                        <br />
                        Transmissões
                        <br />
                        Publicidade
                        <br />
                        Eventos
                        <br />
                        VFX
                    </p>
                </motion.div>

                <div
                    className='pc'
                    style={{
                        background: '#111',
                        height: '100vh',
                        width: '149.5vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                        position: 'absolute',
                    }}>
                    <AnimatedImage/>
                    <img
                        className='frame-pc'
                        style={{
                            position: 'absolute',
                            top: '-49%',
                            height: '185%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        src={macFrame}
                        alt=''
                    />
                    <img
                        className='logo-pc'
                        style={{
                            position: 'absolute',
                            top: '17%',
                            left: '28.2%',
                            height: '31%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        src={logo}
                        alt=''
                    />
                </div>
            </motion.div>
        </Sticky>
    );
}

export default First;
