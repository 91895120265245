import React from 'react';
import GlobalStyles from './styles/global';
import { Main, Section } from './styles';

import Navbar from './components/Navbar';
import First from './components/First';
import Second from './components/Second';

import * as functions from './utils/functions.js';
import { useViewportScroll } from 'framer-motion';

const alturaTotal = 2000;

function App() {
    const { scrollYProgress } = useViewportScroll();

    React.useEffect(() => {
        function scroll() {
            console.log('Scroll: ' + functions.adaptaAlturaInv(alturaTotal, [scrollYProgress.get()])[0].toFixed(2));
        }
        window.addEventListener('scroll', scroll);
        return () => window.removeEventListener('scroll', scroll);
    }, [scrollYProgress]);

    React.useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);
    return (
        <>
            <GlobalStyles />
            <Main
                style={{
                    //backgroundColor: 'yellow',
                    width: '100%',
                    left: 0,
                    right: 0,
                    margin: '0 auto',
                    height: alturaTotal.toString() + 'vh',
                }}>
                <Section style={{ height: '500vh' }}>
                    <First altura={alturaTotal} />
                </Section>
                <Section style={{ height: '410vh' }}>
                    <Second altura={alturaTotal} />
                </Section>
            </Main>
        </>
    );
}

export default App;

/*
<Navbar />
 */
