import React, { useState, useEffect } from 'react';
import { useViewportScroll } from 'framer-motion';

import { HTMLAttributes } from 'react';
type AnimatedImageProps = HTMLAttributes<HTMLDivElement>;

const numFrames = 377;
const image: Array<HTMLImageElement> = [new Image()]; //require('../../images/video_tela/video_tela000.webp')
image[0].src = require('../../images/video_tela/video_tela000.webp');
image[0].id = '0';
image[0].setAttribute('style', ' width: 100%');

function AnimatedImage(props: AnimatedImageProps) {
    const { scrollY } = useViewportScroll();
    const [scrolled, setScrolled] = useState(0);

    const velocidade = 6;

    var frame = Math.floor(scrolled / (80 * (1 / velocidade)));
    if (frame >= numFrames) {
        frame = numFrames;
    }

    useEffect(() => {
        function updateVideo() {
            setScrolled(scrollY.get());
        }
        window.addEventListener('scroll', updateVideo);
        return () => window.removeEventListener('scroll', updateVideo);
    }, [scrollY]);

    useEffect(() => {
        for (let i = 0; i < numFrames; i++) {
            var id = i;
            if (id < 69) {
                id = 0;
            }

            const frameImage = require(`../../images/video_tela/video_tela${id
                .toString()
                .padStart(3, '0')}.webp`);
            image.push(new Image());
            image[i].src = frameImage;
            image[i].id = i.toString();
            image[i].setAttribute('style', ' width: 149.5vh');
        }
    }, []);

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: image[frame].outerHTML }} />
        </div>
    );
}

export default AnimatedImage;
//
