export function adaptaAltura(a, valoresIn){
    const valoresOut = valoresIn.map((num) => num * 1000 / a);
    //console.log(valoresIn + ', ' + valoresOut);
    return valoresOut;
}

export function adaptaAlturaInv(a, valoresIn){
    const valoresOut = valoresIn.map((num) => num * a / 1000);
    //console.log(valoresIn + ', ' + valoresOut);
    return valoresOut;
}